const PRODUCTS = [
/*  EXAMPLE:  
    {
        id: 9999,
        gallery: [ "path-to-img1.jpg", "path-to-img2.jpg" ],
        title: "title",
        html: {
            before: "<p>Before.</p>",
            after: "<p>After.</p>",
            quote: "quote",
            quoteName: "name"
        },
        materials: "materials",
        size: "size",
        time: "time",
        location: "location"
    },*/
    {
        id: 1,
        gallery: [
            "images/m01-img-1.jpg",
            "images/m01-img-2.jpg",
            "images/m01-img-3.jpg",
            "images/m01-img-4.jpg",
            "images/m01-img-5.jpg",
            "images/m01-img-6.jpg",
            "images/m01-img-7.jpg",
            "images/m01-img-8.jpg"
        ],
        title: "Acrylic Mural Painting",
        html: {
            before: "<p>Unique modern mural in a beautiful and cozy condo, located on the 34th floor, with an incredible view of downtown Toronto. This mural was custom designed for this space, it was hand painted. This ensures the mural complimented the interior design in the condo.</p>"
        },
        materials: "acrylic paints, silver italian patina, finished with gloss varnish",
        size: "9 sq m / 96 sq feet",
        time: "20 hours",
        location: "Toronto, Downtown, ON, CA"
    },
    {
        id: 2,
        gallery: [
            "images/m02-img-1.jpg",
            "images/m02-img-2.jpg",
            "images/m02-img-3.jpg",
            "images/m02-img-4.jpg",
            "images/m02-img-5.jpg"
        ],
        title: "Acrylic Mural Painting",
        html: {
            before: "<p>Delicate mural fit for a princess made with acrylic paint in this modern and stylish child’s bedroom with soft colours. The fairy pink castle is surrounded by white clouds, and gives the small owner of this room the feeling of being in a real fairy tale.</p>",
        },
        materials: "acrylic paints, finished with gloss varnish",
        size: "5 sq m / 53 sq feet",
        time: "30 hours",
        location: "Minesing, ON, CA"
    },
    {
        id: 3,
        gallery: [
            "images/m03-img-1.jpg",
            "images/m03-img-2.jpg",
            "images/m03-img-3.jpg",
            "images/m03-img-4.jpg",
            "images/m03-img-5.jpg",
        ],
        title: "Acrylic Mural Painting",
        html: {
            before: "<p>The Tropical Graphic Leaves mural was created for a guest bathroom and was applied using acrylic paints. This mural brightens up the space and makes the guest bathroom unique.</p>",
            after: "<p>Work was done for \"SurfaceCulture\" Company.</p>",
        },
        materials: "acrylic paints, finished with satin clear interior varnish",
        time: "19 hours",
        location: "Canada"
    },
    {
        id: 4,
        gallery: [
            "images/m04-img-1.jpg",
            "images/m04-img-2.jpg",
            "images/m04-img-3.jpg",
            "images/m04-img-4.jpg",
            "images/m04-img-5.jpg",
            "images/m04-img-6.jpg"
        ],
        title: "Acrylic Mural Painting",
        html: {
            before: "<p>Located with a beautiful view of a nearby forest, the palm branches mural brings a calm, relaxing feel to a warm and minimalist master bedroom.</p>"
        },
        materials: "acrylic paints, finished with gloss varnish",
        size: "9 sq m / 96 sq feet",
        time: "16 hours",
        location: "Minesing, ON, CA"
    },
    {
        id: 5,
        gallery: [
            "images/m05-img-1.jpg",
            "images/m05-img-2.jpg",
            "images/m05-img-3.jpg",
            "images/m05-img-4.jpg",
            "images/m05-img-5.jpg"
        ],
        title: "Acrylic Mural Painting",
        html: {
            before: "<p>This forest mural brings nature from right outside the window into this little boy’s bedroom. He loves hunting and the forest. This custom created mural brings these interests on to the wall of his bedroom, so he can enjoy nature even when he is indoors.</p>"
        },
        materials: "acrylic paints, finished with gloss varnish",
        size: "7 sq m / 75 sq feet",
        time: "27 hours",
        location: "Minesing, ON, CA"
    },
    {
        id: 6,
        gallery: [
            "images/cp06-img-1.jpg",
            "images/cp06-img-2.jpg",
            "images/cp06-img-3.jpg",
            "images/cp06-img-4.jpg",
            "images/cp06-img-5.jpg",
            "images/cp06-img-6.jpg",
        ],
        title: "Colourful Life",
        html: {
            before: "<p>“Colourful Life” is an acrylic painting created on gallery canvas. This painting was custom designed and created for the client.</p>",
            quote: "Thank you very much to Yana, now my sweet home has two art pieces. They are both adorable, I love the colors and work in total! I have never met Yana, but she could paint me something that I even did not expect. I told her the idea and she managed to do the best job of it ! I am in love with my paintings and people who visit me always fall in love with those art pieces. I definitely recommend Yana and will get back to her. I am also thinking to get a tatoo and want her to be the person who will help me with design!  Thank you again ❤",
            quoteName: "Ksenia"
        },
        materials: "3d decorative pasta, acrylic paints, silver italian patina, finished with gloss varnish, made on gallery canvas",
        size: "48 in by 60 in",
        location: "Toronto, ON, CA"
    },
    {
        id: 7,
        gallery: [
            "images/dp07-img-1.jpg",
            "images/dp07-img-2.jpg",
            "images/dp07-img-3.jpg",
            "images/dp07-img-4.jpg",
            "images/dp07-img-5.jpg",
            "images/dp07-img-6.jpg",
            "images/dp07-img-7.jpg",
            "images/dp07-img-8.jpg",
            "images/dp07-img-9.jpg",
            "images/dp07-img-10.jpg",
            "images/dp07-img-11.jpg",
            "images/dp07-img-12.jpg",
            "images/dp07-img-13.jpg",
            "images/dp07-img-14.jpg",
            "images/dp07-img-15.jpg",
            "images/dp07-img-16.jpg",
            "images/dp07-img-17.jpg",
            "images/dp07-img-18.jpg",
            "images/dp07-img-19.jpg",
            "images/dp07-img-20.jpg",
            "images/dp07-img-21.jpg",
            "images/dp07-img-22.jpg",
            "images/dp07-img-23.jpg",
            "images/dp07-img-24.jpg",
            "images/dp07-img-25.jpg",
            "images/dp07-img-26.jpg",
            "images/dp07-img-27.jpg",
            "images/dp07-img-28.jpg",
            "images/dp07-img-29.jpg",
            "images/dp07-img-30.jpg",
            "images/dp07-img-31.jpg",
            "images/dp07-img-32.jpg",
            "images/dp07-img-33.jpg",
            "images/dp07-img-34.jpg",
            "images/dp07-img-35.jpg",
            "images/dp07-img-36.jpg",
            "images/dp07-img-37.jpg",
            "images/dp07-img-38.jpg",
            "images/dp07-img-39.jpg",
            "images/dp07-img-40.jpg",
            "images/dp07-img-41.jpg",
            "images/dp07-img-42.jpg",
            "images/dp07-img-43.jpg",
            "images/dp07-img-44.jpg",
            "images/dp07-img-45.jpg",
            "images/dp07-img-46.jpg",
            "images/dp07-img-47.jpg",
            "images/dp07-img-48.jpg",
            "images/dp07-img-49.jpg",
            "images/dp07-img-50.jpg",
            "images/dp07-img-51.jpg"
        ],
        title: "Decorative plaster",
        html: {
            before: "<p>Decorative Plaster is a stone based plaster. It is either Acrylic or natural lime based. Choosing the plaster depends entirely on the technical specifications and the desired results for the client. The thickness can be from 1mm to 1cm, applied to a specific micro primer and finished with varnish or beeswax depending on the technique chosen.</p>"
        }
    },
    {
        id: 8,
        gallery: [
            "images/m08-img-1.jpg",
            "images/m08-img-2.jpg",
            "images/m08-img-3.jpg",
            "images/m08-img-4.jpg",
            "images/m08-img-5.jpg",
            "images/m08-img-6.jpg",
            "images/m08-img-7.jpg",
            "images/m08-img-8.jpg"
        ],
        title: "Acrylic Mural Painting",
        html: {
            before: "<p>This mural was made to create a unique accent wall in Blush Beauty Bar; a beauty salon in Montreal. This one of a kind mural was created using a unique and individual technique that highlights the magic and beauty this salon gives their clients everyday.</p>",
            quote: "Yana, thank you for your work! You are a great professional!  You have added individuality and creativity to our salon!!! We are very pleased to work with you! We will contact you many more times for sure!  Thanks",
            quoteName: "Blush Beauty Bar team"
        },
        materials: "acrylic paints; gold tint; finish - gloss varnish",
        size: "approximate 9 feet by 7 feet",
        time: "19 hours",
        location: "Montreal, QC, CA"
    },
    {
        id: 9,
        gallery: [
            "images/m09-img-1.jpg",
            "images/m09-img-2.jpg",
            "images/m09-img-3.jpg",
            "images/m09-img-4.jpg",
        ],
        title: "Acrylic Mural Accent Wall",
        html: {
            before: "<p>Abstract and vibrant accent wall brightens up this dining area and was created using acrylic paints. This mural was custom created to make this space more visually wide as well as to brighten it up and make it unique.</p>",
            quote: "I’m in love with my art wall by Yana Litus! Not only she is incredible  artist, but also very warm and friendly person. She took her time to help me decide with colours and theme for the art wall. Everything was awesome from start to finish. And now for many years to come I enjoying my custom art wall. Worth every single dollar  spent:) I would even say best purchase of 2020. Thank you Yana for making my home so stylish, unique and eye catching for all my guests! I’m referring you to all my friends worldwide by the way! So get ready to travel, because you are super talented girl!",
            quoteName: "Elena Kemaeva"
        },
        materials: "acrylic paints, italian gold patina, finished with satin clear interior varnish",
        size: "approximate 9 feet by 7 feet",
        time: "9 hours",
        location: "Maple, ON, CA"
    },
    {
        id: 10,
        gallery: [
            "images/cp10-img-1.jpg",
            "images/cp10-img-2.jpg",
            "images/cp10-img-3.jpg",
            "images/cp10-img-4.jpg",
            "images/cp10-img-5.jpg",
            "images/cp10-img-6.jpg",
            "images/cp10-img-7.jpg",
            "images/cp10-img-8.jpg"
        ],
        title: "Flower Dreams",
        html: {
            before: "<p>“Flower Dreams” is an acrylic painting created using 3D decorative pasta and acrylic paint with a gloss varnish finish on a gallery canvas.</p>",
            quote: "Thank you very much to Yana, now my sweet home has two art pieces. They are both adorable, I love the colors and work in total! I have never met Yana, but she could paint me something that I even did not expect. I told her the idea and she managed to do the best job of it ! I am in love with my paintings and people who visit me always fall in love with those art pieces. I definitely recommend Yana and will get back to her. I am also thinking to get a tatoo and want her to be the person who will help me with design!  Thank you again ❤",
            quoteName: "Ksenia"
        },
        materials: "3d decorative pasta, acrylic paints,  finished with gloss varnish, made on gallery canvas",
        size: "48 in by 36 in",
        location: "Toronto, Downtown, ON, CA"
    },
    {
        id: 11,
        gallery: [
            "images/cp11-img-1.jpg",
            "images/cp11-img-2.jpg",
            "images/cp11-img-3.jpg",
            "images/cp11-img-4.jpg",
            "images/cp11-img-5.jpg",
            "images/cp11-img-6.jpg"
        ],
        title: "Bouquet with a Secret",
        html: {
            before: "<p>“Bouquet with a Secret” was created using 3D decorative pasta and acrylic paint. The secret in this painting is hidden inside the bouquet. There are two lovely faces looking at each other.</p>"
        },
        materials: "3d decorative pasta, acrylic paints, gold italian patina, finished with gloss varnish, made on gallery canvas",
        size: "48 in by 48 in",
        location: "Montreal, QC, CA"
    },
    {
        id: 12,
        gallery: [
            "images/m12-img-1.jpg",
            "images/m12-img-2.jpg",
            "images/m12-img-3.jpg"
        ],
        title: "Acrylic Mural Accent Wall",
        html: {
            before: "<p>This mural was created to create a unique accent wall in Blush Beauty Bar; a beauty salon in Montreal. This one of a kind mural was created using a unique and individual technique that highlights the magic and beauty this salon gives their clients everyday.</p>",
            quote: "Yana, thank you for your work! You are a great professional! You have added individuality and creativity to our salon!!! We are very pleased to work with you! We will contact you many more times for sure!  Thanks",
            quoteName: "Blush Beauty Bar team"
        },
        materials: "acrylic paints; gold tint; finish - gloss varnish",
        size: "approximate 9 feet by 7 feet",
        time: "19 hours",
        location: "Montreal, QC, CA"
    },
    {
        id: 13,
        gallery: [
            "images/cp13-img-1.jpg",
            "images/cp13-img-2.jpg",
            "images/cp13-img-3.jpg"
        ],
        title: "Alcohol Ink Painting",
        html: {
            before: "<p>This painting was created on plastic paper using alcohol ink.</p>"
        },
        materials: "alcohol ink on plastic paper",
        size: "30 by 40 in"
    },
    {
        id: 14,
        gallery: [
            "images/m14-img-1.jpg",
            "images/m14-img-2.jpg",
            "images/m14-img-3.jpg",
            "images/m14-img-4.jpg",
            "images/m14-img-5.jpg",
            "images/m14-img-6.jpg",
            "images/m14-img-7.jpg"
        ],
        title: "Acrylic Accent Wall Painting",
        html: {
            before: "<p>This mural is the focal point on the first wall you see after you enter this bridal salon in New York City. This mural was created to enhance the magic a bridal experiences when shopping for their wedding gown. It was created with a unique and individual technique.</p>"
        },
        materials: "acrylic paints; gold tint; finish - gloss varnish",
        size: "9 feet by 9 feet",
        time: "15 hours",
        location: "New York, NY, USA"
    },
    {
        id: 15,
        gallery: [
            "images/m15-img-1.jpg",
            "images/m15-img-2.jpg",
            "images/m15-img-3.jpg",
            "images/m15-img-4.jpg",
            "images/m15-img-5.jpg",
            "images/m15-img-6.jpg",
            "images/m15-img-7.jpg",
            "images/m15-img-8.jpg"
        ],
        title: "Acrylic Accent Wall Painting",
        html: {
            before: "<p>This mural was created for a space by the fitting room at “Wona” in New York City, Where beautiful brides choose their wedding dresses. This Mural enhances the unique beauty of this bridal salon. It was created with a unique and individual technique.</p>",
        },
        materials: "acrylic paints; gold tint; finish - gloss varnish",
        size: "9 feet by 9 feet",
        time: "13 hours",
        location: "New York, NY, USA"
    },
    {
        id: 16,
        gallery: [
            "images/m16-img-1.jpg",
            "images/m16-img-2.jpg",
            "images/m16-img-3.jpg",
            "images/m16-img-4.jpg",
            "images/m16-img-5.jpg"
        ],
        title: "Acrylic Accent Wall Painting",
        html: {
            before: "<p>This wall is inside the fitting room in the bridal showroom in “Wona” in New York City. It is a beautiful and unique place with a magical atmosphere enhanced by this mural. It was created with a unique and individual technique.</p>",
        },
        materials: "acrylic paints; gold tint; finish - gloss varnish",
        size: "9 feet by 9 feet",
        time: "12 hours",
        location: "New York, NY, USA"
    },
    {
        id: 17,
        gallery: [
            "images/cp17-img-1.jpg",
            "images/cp17-img-2.jpg",
            "images/cp17-img-3.jpg"
        ],
        title: "Alcohol Ink Painting",
        html: {
            before: "<p>This painting was created on plastic paper using alcohol ink.</p>"
        },
        materials: "alcohol ink on plastic paper",
        size: "30 by 40 in"
    },
    {
        id: 18,
        gallery: [
            "images/cp18-img-1.jpg",
            "images/cp18-img-2.jpg",
            "images/cp18-img-3.jpg",
            "images/cp18-img-4.jpg",
            "images/cp18-img-5.jpg",
            "images/cp18-img-6.jpg",
            "images/cp18-img-7.jpg",
            "images/cp18-img-8.jpg"
        ],
        title: "Acrylic Painting on Canvas: “Holiday Night”",
        html: {
            before: "<p>“Holiday Night” was created using acrylic paint on a gallery Canvas.</p>"
        },
        materials: "3d decorative pasta, acrylic paints, silver italian patina, finished with gloss varnish, made on gallery canvas",
        size: "64 in by 97 in",
        location: "Montreal, QC, CA"
    },
    {
        id: 19,
        gallery: [
            "images/m19-img-1.jpg",
            "images/m19-img-2.jpg",
            "images/m19-img-3.jpg"
        ],
        title: "Acrylic Mural Painting",
        html: {
            before: "<p>This picturesque Sicillian street mural was created for this master bedroom. This was designed to bring a cozy feeling and to accommodate a decorative street lantern and beautiful street views.</p>",
            quote: "Really amazing and great masterpiece was made by Yana at my parents balcony. My mother wanted something positive, summerish and vivid to be depicted, something that would coincide with the window, lantern and would enlarge the space of balcony. As you can see - the work is done perfectly. The ground, primer, canvas, other is made or adviced by Yana. By the way, the colours: the latter are still vivid as if were painted yesterday (actually 7 years ago). For me, any art work considered as driven by the deepest inspiration. Only person with the sophisticated and, at the same time, passionate attitude can go for arts as a job. So it's all about Yana. Really, great work by the great artist.",
            quoteName: "Andrii Tronko"
        },
        materials: "acrylic paints, finished with satin varnish",
        size: "9 feet to 6 feet",
        time: "24 hours",
        location: "Poltava, UA"
    },
    {
        id: 20,
        gallery: [
            "images/cp20-img-1.jpg",
            "images/cp20-img-2.jpg",
            "images/cp20-img-3.jpg",
            "images/cp20-img-4.jpg",
            "images/cp20-img-5.jpg"
        ],
        title: "Denim Jacket Painting",
        html: {
            before: "<p>This beautiful painting was created on a denim jacket. It features a lotus design with ornament accents on a galaxy background.</p>"
        },
        materials: "textile paints made on 100% denim jacket, washable",
        size: "12 in by 21 in",
        location: "Montreal, QC, CA"
    },
    {
        id: 22,
        gallery: [
            "images/cp22-img-1.jpg",
            "images/cp22-img-2.jpg",
            "images/cp22-img-3.jpg",
            "images/cp22-img-4.jpg",
            "images/cp22-img-5.jpg",
            "images/cp22-img-6.jpg",
            "images/cp22-img-7.jpg",
            "images/cp22-img-8.jpg"
        ],
        title: "Nature Inspiration Abstraction",
        html: {
            before: "<p>“Nature Inspiration Abstraction” was created using acrylic paint on a gallery canvas.</p>"
        },
        materials: "3d decorative pasta, acrylic paints, epoxy resin, finished with gloss varnish and epoxy resin, made on gallery canvas",
        size: "48 in by 64 in",
        location: "Minesing, ON, CA"
    },
    {
        id: 23,
        gallery: [
            "images/cp23-img-1.jpg",
            "images/cp23-img-2.jpg",
            "images/cp23-img-3.jpg",
            "images/cp23-img-4.jpg",
            "images/cp23-img-5.jpg",
            "images/cp23-img-6.jpg"
        ],
        title: "Alcohol Ink Painting",
        html: {
            before: "<p>This painting was created on plastic paper using alcohol ink.</p>",
            quote: "Yana is such an amazing artist! I wanted to receive her painting the time I first saw it. Her technic is amazing! I enjoy the painting and I definitely will ask her to create some wall art in my apartment.",
            quoteName: "Indira"
        },
        materials: "alcohol ink on plastic paper",
        size: "30 by 40 in",
        location: "Montreal, QC, CA"
    },
    {
        id: 24,
        gallery: [
            "images/cp24-img-1.jpg"
        ],
        title: "Forest Soul",
        html: {
            before: "<p>“Forest Soul” was created using textile paints on 100% silk.</p>"
        },
        materials: "textile paints made on 100% silk",
        size: "21 in by 30 in",
        location: "London, ON, CA"
    },
    {
        id: 25,
        gallery: [
            "images/cp25-img-1.jpg",
            "images/cp25-img-2.jpg",
            "images/cp25-img-3.jpg"
        ],
        title: "Alcohol Ink Painting",
        html: {
            before: "<p>This painting was created on plastic paper using alcohol ink.</p>"
        },
        materials: "alcohol ink on plastic paper",
        size: "30 in by 40 in"
    },
    {
        id: 26,
        gallery: [
            "images/cp26-img-1.jpg",
            "images/cp26-img-2.jpg",
            "images/cp26-img-3.jpg",
            "images/cp26-img-4.jpg",
            "images/cp26-img-5.jpg",
            "images/cp26-img-6.jpg"
        ],
        title: "Tropical Flowers",
        html: {
            before: "<p>“Tropical Flowers’ is an acrylic painting created on a gallery canvas.</p>"
        },
        materials: "3d decorative pasta, oil paint, finished with gloss varnish for oil paints, made on gallery canvas",
        size: "21 in by 30 in",
        location: "London, ON, CA"
    },
    {
        id: 27,
        gallery: [
            "images/m27-img-1.jpg"
        ],
        title: "Acrylic Mural Painting",
        html: {
            before: "<p>This Acrylic mural painting was made in a beauty salon. The original image was taken for the mural with permission from Artist Greg Guillrmin. It was made with the exact same colours and logo as the original, on the wall of the salon.</p>",
        },
        materials: "acrylic paints, finished with gloss varnish",
        size: "6 sq m / 64 sq feet",
        time: "14 hours",
        location: "Toronto, ON, CA"
    },
    {
        id: 28,
        gallery: [
            "images/m28-img-1.jpg",
            "images/m28-img-2.jpg",
            "images/m28-img-3.jpg",
            "images/m28-img-4.jpg"
        ],
        title: "Acrylic Mural Painting",
        html: {
            before: "<p>Geometric graphic mural was created for a Playroom and guest room with a beautiful view of Downtown Toronto. This Mural was made with acrylic paints.</p>"
        },
        materials: "acrylic paints",
        time: "10 hours",
        location: "Toronto, ON, CA"
    },
    {
        id: 29,
        gallery: [
            "images/cp29-img-1.jpg",
            "images/cp29-img-2.jpg",
            "images/cp29-img-3.jpg",
            "images/cp29-img-4.jpg",
            "images/cp29-img-5.jpg"
        ],
        title: "Girls Gathering",
        html: {
            before: "<p>“Girls Gathering” is an acrylic painting created on a gallery canvas.</p>"
        },
        materials: "3d decorative pasta, acrylic paints, gold italian patina, finished with gloss varnish, made on gallery canvas",
        size: "48 in by 48 in",
        location: "Austria, Vienna"
    },
    {
        id: 30,
        gallery: [
            "images/cp30-img-1.jpg",
            "images/cp30-img-2.jpg",
            "images/cp30-img-3.jpg",
            "images/cp30-img-4.jpg"
        ],
        title: "Alcohol Ink Painting",
        html: {
            before: "<p>This painting was created on plastic paper using alcohol ink.</p>"
        },
        materials: "alcohol ink on plastic paper",
        size: "30 in by 40 in"
    },
    {
        id: 31,
        gallery: [
            "images/m31-img-1.jpg",
            "images/m31-img-2.jpg",
            "images/m31-img-3.jpg",
            "images/m31-img-4.jpg",
            "images/m31-img-5.jpg",
            "images/m31-img-6.jpg"
        ],
        title: "Terrace Mural Painting",
        html: {
            before: "<p>This mural was created as a backdrop on an outdoor terrace for an Authentic Italian Restaurant “Fiorellino” in Montreal. The pattern and logo of the restaurant was painted by hand with a brush and acrylic paints.</p>"
        },
        materials: "acrylic paints",
        size: "13 feet by 9 feet",
        time: "30 hours",
        location: "Montreal, QC, CA"
    },
    {
        id: 32,
        gallery: [
            "images/cp32-img-1.jpg",
            "images/cp32-img-2.jpg",
            "images/cp32-img-3.jpg",
            "images/cp32-img-4.jpg",
            "images/cp32-img-5.jpg",
            "images/cp32-img-6.jpg",
            "images/cp32-img-7.jpg",
            "images/cp32-img-8.jpg"
        ],
        title: "Midnight Horizontal",
        html: {
            before: "<p>“Midnight Horizontal” is an acrylic painting created on a gallery canvas.</p>"
        },
        materials: "3d decorative pasta, acrylic paints, gold italian patina, finished with gloss varnish, made on gallery canvas",
        size: "32 in by 48 in",
        location: "Montreal, QC, CA"
    },
    {
        id: 33,
        gallery: [
            "images/m33-img-1.jpg",
            "images/m33-img-2.jpg",
            "images/m33-img-3.jpg",
            "images/m33-img-4.jpg",
            "images/m33-img-5.jpg"
        ],
        title: "Acrylic Mural Painting",
        html: {
            before: "<p>Boys room. This little boy loves space and science. He loves to explore every day and with this mural he can do that in his bedroom. The mural was created using acrylic paint and the stars were created using glow in the dark paint.</p>",
        },
        materials: "acrylic paints; finish - gloss varnish; stars made by glow in the night neon acrylic paint",
        size: "40 sq feet / 4 sq m",
        time: "17 hours",
        location: "Innisfil, ON, CAInnisfil, ON, CA"
    },
    {
        id: 34,
        gallery: [
            "images/dp34-img-1.jpg",
            "images/dp34-img-2.jpg",
            "images/dp34-img-3.jpg",
            "images/dp34-img-4.jpg",
            "images/dp34-img-5.jpg",
            "images/dp34-img-6.jpg"
        ],
        title: "“The Sun” Decorative Wall",
        html: {
            before: "<p>This decorative wall was created using decorative concrete plaster and was tinted using acrylic tint.</p>",
            quote: "<p>Yana is my go-to person when I want to add something special to my home décor. She created 3 wonderful paintings for me and a wall-art which mesmerizes every person who walks into the house. Yana has a great vision and her recommendations are always on point!</p><p>Not only she is an amazing artist, but also a very pleasant person to work with! If you want to have a unique one-of-a-kind piece of art, I strongly recommend reaching out to Yana and I am sure you won't be disappointed!</p>",
            quoteName: "Anastasia Baikovskaya"
        },
        materials: "decorative concrete plaster, acrylic tint for concrete, gold pigment, finished with satin varnish",
        size: "9 feet by 8 feet",
        time: "2 days, including work time and drying time",
        location: "Montreal, QC, CA"
    },
    {
        id: 35,
        gallery: [
            "images/m35-img-1.jpg",
            "images/m35-img-2.jpg",
            "images/m35-img-3.jpg"
        ],
        title: "Acrylic Mural Painting",
        html: {
            before: "<p>This educational mural was created for a child's bedroom using acrylic paints. The little owner of this room loves discovering new animals and loves to explore new places. This mural explores these interests and presents them in a way that the child will be able to learn while exploring things they love.</p>",
        },
        materials: "acrylic paints",
        size: "9 feet by 12 feet",
        time: "25 hours",
        location: "Innisfil, ON, CA"
    },
    {
        id: 36,
        gallery: [
            "images/m36-img-1.jpg",
            "images/m36-img-2.jpg",
            "images/m36-img-3.jpg",
            "images/m36-img-4.jpg",
            "images/m36-img-5.jpg",
            "images/m36-img-6.jpg"
        ],
        title: "Acrylic Mural Painting",
        html: {
            before: "<p>Beautiful floral mural for a baby girl’s Nursery, made using acrylic paints.</p>"
        },
        materials: "acrylic paints",
        size: "13 feet by 9 feet",
        time: "17 hours",
        location: "Newmarket, ON, CA"
    },
    {
        id: 37,
        gallery: [
            "images/m37-img-1.jpg",
            "images/m37-img-2.jpg",
            "images/m37-img-3.jpg",
            "images/m37-img-4.jpg"
        ],
        title: "Acrylic Mural Painting",
        html: {
            before: "<p>Game Mural was created for a teenager’s room, with acrylic paints. It was custom created to express his love of playstation and bright colours.</p>"
        },
        materials: "acrylic paints",
        time: "9 hours",
        location: "Barrie, ON, CA"
    },
    {
        id: 38,
        gallery: [
            "images/m38-img-1.jpg",
            "images/m38-img-2.jpg",
            "images/m38-img-3.jpg"
        ],
        title: "Acrylic Mural Painting",
        html: {
            before: "<p>Acrylic Mural For a Little girls room. This graphic pink and fabulous city in the clouds, with a princess castle, create a wonderful space for her imagination to flourish.</p>"
        },
        materials: "acrylic paints and waterbased interior satin varnish for making this wall durable and washable",
        size: "13 feet by 9 feet",
        time: "12 hours",
        location: "Mississauga, ON, CA"
    },
    {
        id: 39,
        gallery: [
            "images/dp39-img-1.jpg",
            "images/dp39-img-2.jpg",
            "images/dp39-img-3.jpg",
            "images/dp39-img-4.jpg",
            "images/dp39-img-5.jpg"
        ],
        title: "Decorative Textured Fireplace",
        html: {
            before: "<p>This decorative fireplace was created using decorative natural plaster and was finished with Beeswax.</p>"
        },
        materials: "decorative natural plaster, finished with bee wax",
        size: "7 feet by 8 feet",
        time: "2 days, including work time and drying time",
        location: "Barrie, ON, CA"
    },
    {
        id: 40,
        gallery: [
            "images/m40-img-1.jpg",
            "images/m40-img-2.jpg",
            "images/m40-img-3.jpg",
            "images/m40-img-4.jpg"
        ],
        title: "Acrylic Mural Painting",
        html: {
            before: "<p>Game mural was created in a teenager’s room with acrylic paints. The owner of this room loves games and bright colours, sony playstation theme was perfect for him.</p>"
        },
        materials: "acrylic paints",
        time: "8 hours",
        location: "Barrie, ON, CA"
    },
    {
        id: 41,
        gallery: [
            "images/dp41-img-1.jpg",
            "images/dp41-img-2.jpg",
            "images/dp41-img-3.jpg",
            "images/dp41-img-4.jpg",
            "images/dp41-img-5.jpg",
            "images/dp41-img-6.jpg"
        ],
        title: "Decorative Concrete Plaster Accent Wall",
        html: {
            before: "<p>This textured accent was created using concrete plaster and was tinted with acrylic paints.</p>"
        },
        materials: "concrete plaster, acrylic durable and washable paints, finished with interior gloss varnish",
        size: "6 feet by 6 feet",
        time: "2 days, including work time and drying time",
        location: "Minesing, ON, CA"
    },
    {
        id: 42,
        gallery: [
            "images/dp42-img-1.jpg",
            "images/dp42-img-2.jpg",
            "images/dp42-img-3.jpg"
        ],
        title: "Decorative Concrete Accent Wall",
        html: {
            before: "<p>This accent was created using concrete plaster and was tinted with acrylic paints and gold pigment for “Blush Beauty Bar” Salon.</p>",
            quote: "Yana, thank you for your work!  You are a great professional!  you have added individuality and creativity to our salon!!!  We are very pleased to work with you!  We will contact you many more times for sure!  Thanks",
            quoteName: "Blush Beauty Bar team"
        },
        materials: "concrete plaster, acrylic paints, gold pigment, finished with interior satin varnish",
        size: "9 feet by 9 feet",
        time: "3 days, including work time and drying time",
        location: "Montreal, QC, CA"
    },
    {
        id: 43,
        gallery: [
            "images/dp43-img-1.jpg",
            "images/dp43-img-2.jpg",
            "images/dp43-img-3.jpg"
        ],
        title: "Decorative Bas-relief Marmorino Wall",
        html: {
            before: "<p>This decorative bas-relief marmorino wall was created for a master bathroom using natural plaster and was finished with an interior varnish.</p>"
        },
        materials: "decorative natural plaster, finished with durable and washable interior varnish",
        size: "6 feet by 9 feet",
        time: "4 days, including work time and drying time",
        location: "Toronto, ON, CA"
    },
    {
        id: 44,
        gallery: [
            "images/m44-img-1.jpg",
            "images/m44-img-2.jpg",
            "images/m44-img-3.jpg",
            "images/m44-img-4.jpg",
            "images/m44-img-5.jpg",
            "images/m44-img-6.jpg",
            "images/m44-img-7.jpg",
            "images/m44-img-8.jpg"
        ],
        title: "Acrylic Mural Painting",
        html: {
            before: "<p>This tropical graphic leaves mural was made in a guest bathroom using acrylic paints. The idea was to make the guest bathroom bright and unique.</p>"
        },
        materials: "decorative plaster plazma3d, acrylic paints",
        time: "19 hours",
        location: "London, ON, CA"
    },
    {
        id: 45,
        gallery: [
            "images/dp45-img-1.jpg",
            "images/dp45-img-2.jpg",
            "images/dp45-img-3.jpg",
            "images/dp45-img-4.jpg"
        ],
        title: "Decorative Concrete Accent Wall Beside Stairs",
        html: {
            before: "<p>This decorative accent wall was created using concrete plaster and concrete tint.</p>"
        },
        materials: "concrete plaster, concrete tint, finished with interior gloss varnish",
        size: "approximate size 140 sq feet",
        time: "4 days, including work time and drying time",
        location: "Innisfil, ON, CA"
    },
    {
        id: 46,
        gallery: [
            "images/dp46-img-1.jpg",
            "images/dp46-img-2.jpg",
            "images/dp46-img-3.jpg",
            "images/dp46-img-4.jpg",
            "images/dp46-img-5.jpg"
        ],
        title: "Remodeled Vanity",
        html: {
            before: "<p>This was a facelift for an old vanity created using acrylic paint and epoxy resin.</p>"
        },
        materials: "acrylic paints, epoxy resin",
        size: "24 in by 5 feet",
        time: "2 days, including work time and drying time",
        location: "London, ON, CA"
    },
    {
        id: 47,
        gallery: [
            "images/dp47-img-1.jpg",
            "images/dp47-img-2.jpg",
            "images/dp47-img-3.jpg",
            "images/dp47-img-4.jpg",
            "images/dp47-img-5.jpg",
            "images/dp47-img-6.jpg",
            "images/dp47-img-7.jpg"
        ],
        title: "Decorative Plaster Accent Wall Beside Stairs",
        html: {
            before: "<p>This decorative plaster wall was created using Italian marmorino natural plaster, 3D plazma, gold Italian patina and was polished with beeswax.</p>"
        },
        materials: "decorative Italian marmorino natural plaster, 3d plazma, gold italian patina, polished with bee wax",
        size: "approximate size 19 feet by 12 feet",
        time: "4 days, including work time and drying time",
        location: "Minesing, ON, CA"
    },
    {
        id: 48,
        gallery: [
            "images/dp48-img-1.jpg",
            "images/dp48-img-2.jpg",
            "images/dp48-img-3.jpg",
            "images/dp48-img-4.jpg",
            "images/dp48-img-5.jpg"
        ],
        title: "Decorative Velvet Textured Fireplace",
        html: {
            before: "<p>This decorative velvet textured fireplace was created using decorative velvet/silk plaster.</p>"
        },
        materials: "decorative velvet/silk plaster",
        size: "90 in by 70 in",
        time: "2 days, including work time and drying time",
        location: "Innisfil, ON, CA"
    },
    {
        id: 49,
        gallery: [
            "images/dp49-img-1.jpg",
            "images/dp49-img-2.jpg",
            "images/dp49-img-3.jpg",
            "images/dp49-img-4.jpg",
            "images/dp49-img-5.jpg",
            "images/dp49-img-6.jpg",
            "images/dp49-img-7.jpg",
            "images/dp49-img-8.jpg"
        ],
        title: "Decorative Plaster Fireplace",
        html: {
            before: "<p>This decorative fireplace in a living room was created using natural marmorino plaster from italy. This idea was to make this fireplace look like it was made with a real slab of stone. It was finished with a natural beeswax and sealer.</p>",
        },
        size: "48 in by 60 in",
        time: "4 days, including work time and drying time",
        location: "Toronto, Downtown, ON, CA"
    },
    {
        id: 50,
        gallery: [
            "images/m50-img-1.jpg",
            "images/m50-img-2.jpg",
            "images/m50-img-3.jpg"
        ],
        title: "Acrylic Mural Painting",
        html: {
            before: "<p>Graphic accent wall in a gym was made with acrylic paints in the Montreal hotel, “Terrasse Hotel Royale”. The idea was to create a motivational accent and make the gym more unique.</p>"
        },
        materials: "acrylic paints",
        time: "9 hours",
        location: "Montreal, QC, CA"
    },
    {
        id: 51,
        gallery: [
            "images/m51-img-1.jpg"
        ],
        title: "Acrylic Photo Backdrop Painting",
        html: {
            before: "<p>Before</p>",
            quote: "I’m very pleased with Yana’s work. Everything was painted very beautiful, professionally and on time."
        },
        materials: "acrylic paints",
        size: "13 feet by 7 feet",
        time: "12 hours",
        location: "Poltava, UA"
    },
    {
        id: 52,
        gallery: [
            "images/m52-img-1.jpg"
        ],
        title: "Acrylic Photo Backdrop Painting",
        html: {
            before: "<p>This photo backdrop was made for a modern lounge bar, “Jack Bar” for a themed club party.</p>",
            quote: "I’m very pleased with Yana’s work. Everything was painted very beautiful, professionally and on time."
        },
        materials: "acrylic paints",
        size: "12 feet by 8 feet",
        time: "10 hours",
        location: "Poltava, UA"
    },
    {
        id: 53,
        gallery: [
            "images/m53-img-1.jpg"
        ],
        title: "Acrylic Mural Painting",
        html: {
            before: "<p>This Mural was made for a modern lounge bar, “Jack Bar” using acrylic paints.</p>",
            quote: "I’m very pleased with Yana’s work. Everything was painted very beautiful, professionally and on time."
        },
        materials: "acrylic paints",
        size: "16 feet by 9 feet",
        time: "19 hours",
        location: "Poltava, UA"
    },
    {
        id: 54,
        gallery: [
            "images/m54-img-1.jpg"
        ],
        title: "Acrylic Mural Painting",
        html: {
            before: "<p>This Mural was made for a modern lounge bar, “Jack Bar” using acrylic paints.</p>",
            quote: "I’m very pleased with Yana’s work. Everything was painted very beautiful, professionally and on time."
        },
        materials: "acrylic paints",
        size: "approximate size 12 feet by 6 feet",
        time: "9 hours",
        location: "Poltava, UA"
    },
    {
        id: 55,
        gallery: [
            "images/dp55-img-1.jpg",
            "images/dp55-img-2.jpg",
            "images/dp55-img-3.jpg",
            "images/dp55-img-4.jpg",
            "images/dp55-img-5.jpg",
            "images/dp55-img-6.jpg"
        ],
        title: "Bas-relief",
        html: {
            before: "<p>This unique fireplace connects marble and wood. The space between the wood and the dark marble is connected by imitating the texture of the marble and wood as well as imitating the colour of the marble.</p>",
        },
        materials: "wings was made using gypsum, after painted with acrylic paints and finished with gloss clear varnish",
        size: "approximate 96 sq feet / 9sq m",
        time: "7 days, including work time and drying time",
        location: "Richmond Hill, ON,CA"
    },
    {
        id: 56,
        gallery: [
            "images/dp56-img-1.jpg",
            "images/dp56-img-2.jpg",
            "images/dp56-img-3.jpg",
            "images/dp56-img-4.jpg",
            "images/dp56-img-5.jpg"
        ],
        title: "Decorative Plaster Backsplash",
        html: {
            before: "<p>The idea behind this decorative plaster backsplash was to connect the black countertops and the white cabinets while adding a gold accent between them.</p>"
        },
        materials: "decorative plaster plazma 3d and durable varnish for backsplash to make it washable and durable",
        size: "5 sq m / 53 sq feet",
        time: "3 days, including work time and drying time",
        location: "Barrie, ON, CA"
    },
    {
        id: 57,
        gallery: [
            "images/dp57-img-1.jpg",
            "images/dp57-img-2.jpg",
            "images/dp57-img-3.jpg"
        ],
        title: "Decorative Accent Wall",
        html: {
            before: "<p>This decorative accent wall was created in a master bedroom, with a decorative silk texture plaster.</p>"
        },
        materials: "decorative silk texture plaster",
        size: "16 feet by 9 feet",
        time: "2 days, including work time and drying time",
        location: "London, ON, CA"
    },
    {
        id: 58,
        gallery: [
            "images/m58-img-1.jpg",
            "images/m58-img-2.jpg",
            "images/m58-img-3.jpg",
            "images/m58-img-4.jpg",
            "images/m58-img-5.jpg",
            "images/m58-img-6.jpg",
            "images/m58-img-7.jpg",
            "images/m58-img-8.jpg"
        ],
        title: "Acrylic Mural Painting",
        html: {
            before: "<p>Modern and unique mural in a home office made with acrylic paints.</p>"
        },
        materials: "acrylic paints",
        size: "approximate 118 sq feet / 11sq m",
        time: "10 hours",
        location: "London,ON, CA"
    }
];